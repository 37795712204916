<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('university_informations').toUpper() }}</h5>
        <b-card class="soft mb-5">
            <b-row>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('registration_semester') }}</label>
                        <div>
                            <span>{{ getObjectValue(studentProgram, 'registration_semester_academic_year') }} </span>
                            <span>&nbsp;-&nbsp;</span>
                            <span>{{ studentProgram.registration_semester ? this.getSemester(studentProgram.registration_semester) : '-' }}</span>
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('registration_date') }}</label>
                        <div>{{ studentProgram.registration_date ? this.toLocaleDate(studentProgram.registration_date) : '-' }}</div>
                    </div>
                </b-col>

                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('prep_status') }}</label>
                        <div>{{ getLocaleText(studentProgram, 'prep_status_name','-') }}</div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('student_status') }}</label>
                        <div>{{ getLocaleText(studentProgram, 'student_status_text','-') }}</div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('semester_status') }}</label>
                        <div>{{ getLocaleText(studentProgram, 'semester_status_name','-') }}</div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('class') }}</label>
                        <div>{{ getLocaleText(studentProgram, 'class_name','-') }}</div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('curriculum') }}</label>
                        <div>{{ getLocaleText(studentProgram, 'curriculum_name') }}</div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('irregular') }}</label>
                        <div>{{ studentProgram.irregular ? getYesNoText(studentProgram.irregular, '-') : '-' }}</div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col cols="12" lg="6">
                <b-card :header="$t('graduation_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduation_semester') }}</label>
                                <div>
                                    {{ getObjectValue(studentProgram, 'graduation_semester_academic_year','') }}
                                    -
                                    {{ studentProgram.graduation_semester_semester ? this.getSemester(studentProgram.graduation_semester_semester) : '' }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduation_decision_date') }}</label>
                                <div>{{ studentProgram.graduation_date ? this.toLocaleDate(studentProgram.graduation_date) : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduation_decision_number') }}</label>
                                <div>{{ getObjectValue(studentProgram, 'graduation_decision_number') }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" lg="6">
                <b-card :header="$t('diploma_information')" class="soft mb-5">
                    <b-row>

                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('diploma_status') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'diploma_status_name', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('diploma_supplement_status') }}</label>
                                <div>{{ this.getLocaleText(studentProgram, 'diploma_supplement_status', '-') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('diploma_date') }}</label>
                                <div>{{ studentProgram.diploma_date ? this.toLocaleDate(studentProgram.diploma_date) : '-' }}</div>
                            </div>
                        </b-col>
                        <!--
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('diploma_supplement') }}</label>
                                <div>-</div>
                            </div>
                        </b-col>
                        -->
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('diploma_number') }}</label>
                                <div>{{ getObjectValue(studentProgram, 'diploma_number') }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" lg="12">
                <b-card :header="$t('leaving_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('reason_for_leaving') }}</label>
                                <div>
                                    {{ this.getLocaleText(studentProgram, 'leaving_reason_name', '-') }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('leaving_semester') }}</label>
                                <div>
                                    {{ getObjectValue(studentProgram, 'leaving_semester_academic_year','') }}
                                    -
                                    {{ studentProgram.leaving_semester_semester ? this.getSemester(studentProgram.leaving_semester_semester) : '' }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('leaving_date') }}</label>
                                <div>{{ studentProgram.leaving_date ? this.toLocaleDate(studentProgram.leaving_date) : '-' }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-button variant="primary"
                  @click="updateFormShow"
                  v-if="checkPermission('studentprogram_update') && editMode">
            {{ $t('edit') }}
        </b-button>

        <CommonModal ref="formModal" size="xxl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()"
                     v-if="checkPermission('studentprogram_update') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal';

    // Update Form
    import UpdateForm from './UpdateForm';

    export default {
        components: {
            CommonModal,
            UpdateForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null
            }
        },
        methods: {
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            updateFormShow() {
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            }
        }
    }
</script>

